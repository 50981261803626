import '../../asset/css/table.css';
import '../../asset/css/style.css';

import { Spin, Table, Tooltip } from 'antd';

import Constant from '../../util/Constant.json';
import React from 'react';
import SiteAPI from '../../service/Sites';
import { getURL } from '../../util/index';
import jp from "jsonpath";

const ERROE_FILTER_PATH = '@.error =="E" || @.warehouse=="EW" || @.warehouse=="EWCN" || @.warehouse=="EWAU" || @.ledger=="EL"'

class DaySites extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sites: [],
            isAPICallNeeded: false,
            loader: true
        };
        // console.log("DaySites :: ", this.props);
    }


    getJSONPathFilterExpression(selectedFlags) {

        // console.log(selectedFlags)
        let filterExpression = '$.x[?(';
        selectedFlags.forEach((flag, index) => {
            // console.log(flag.flagName === 'error')
            filterExpression = filterExpression + (index === 0 ?
                flag.flagName === 'error' ? ERROE_FILTER_PATH : `@.${flag.flagName}` :
                flag.flagName === 'error' ? '|| ' + ERROE_FILTER_PATH : ` || @.${flag.flagName}`)
        });
        return filterExpression + ')]'
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isAPICallNeeded !== this.state.isAPICallNeeded) {
            this.setState({ loader: true, isAPICallNeeded: this.props.isAPICallNeeded, sites: [] }, async () => {
                this.setState({ sites: await this.getSiteAPIResponse(), loader: false })
            });;
        }
    }

    async getSiteAPIResponse() {
        const sidebarDataResponse = JSON.parse(localStorage.getItem('sideBarData')).message;
        const { country, selectedDate, selectedFlags, filterData } = this.props;
        let { siteName, status } = filterData
        let tempStatus = status.length ? (status.length > 1 ? "0,1" : (status[0] === "Active" ? "1" : "0")) : "0,1"
        let url = getURL(Constant.URL.GET_DAY_SITE_DATA);
        url.searchParams.append('countryIso', sidebarDataResponse[country].countryIso);
        url.searchParams.append('fromDate', selectedDate);
        url.searchParams.append('Status', tempStatus);
        let result = await SiteAPI.getDayData(url.href, {});
        // console.log("day result :: ", result);
        // result = {
        //     "response": {
        //         "regions": [
        //             {
        //                 "name": "ANZ",
        //                 "countries": [
        //                     {
        //                         "name": "Australia",
        //                         "sites": {
        //                             "active": [
        //                                 {
        //                                     "SiteId": "0190",
        //                                     "SiteName": "0190-FXB - Narellan",
        //                                     "error": null,
        //                                     "ledger": "EL",
        //                                     "warehouse": null,
        //                                     "oracleFinance":"EF"
        //                                 },
        //                                 {
        //                                     "SiteId": "0191",
        //                                     "SiteName": "0191-Helloworld - Greenhills",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0193",
        //                                     "SiteName": "0193-FXB - Tuggerah",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0195",
        //                                     "SiteName": "0195-FXB - ROBINA",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0197",
        //                                     "SiteName": "0197-FXB - KOTARA",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0199",
        //                                     "SiteName": "0199-FXB - CHARLESTOWN",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0200",
        //                                     "SiteName": "0200-SYDAIR - T2 Virgin Domestic",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0214",
        //                                     "SiteName": "0214-SYDAIR - T3 Qantas Dom Gate 3",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0217",
        //                                     "SiteName": "0217-FXB - Balgowlah",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0221",
        //                                     "SiteName": "0221-FXB - QVB Sydney",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0227",
        //                                     "SiteName": "0227-FXB - Figtree",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0244",
        //                                     "SiteName": "0244-FXB - Penrith",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0245",
        //                                     "SiteName": "0245-FXB - Bondi Junction",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0247",
        //                                     "SiteName": "0247-FXB- Mt Druitt",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0249",
        //                                     "SiteName": "0249-FXB - Macarthur Square",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0257",
        //                                     "SiteName": "0257-Travelex at HSBC - Macquarie Centre",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0267",
        //                                     "SiteName": "0267-FXB - Wetherill Park",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0268",
        //                                     "SiteName": "0268-FXB - Shellharbour",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0269",
        //                                     "SiteName": "0269-FXB - Warringah Mall",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0273",
        //                                     "SiteName": "0273-HWT - Blacktown",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0277",
        //                                     "SiteName": "0277-FXB - Burwood",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0279",
        //                                     "SiteName": "0279-FXB - Liverpool",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0282",
        //                                     "SiteName": "0282-HWT - Miranda",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0285",
        //                                     "SiteName": "0285-FXB - Castle Towers",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0289",
        //                                     "SiteName": "0289-HWT - Erina",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0293",
        //                                     "SiteName": "0293-HWT - East Gardens",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0294",
        //                                     "SiteName": "0294-FXB - Hornsby",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0295",
        //                                     "SiteName": "0295-HWT - Albury",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0311",
        //                                     "SiteName": "0311-MELAIR - T2 A/side Arr Baggage",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0312",
        //                                     "SiteName": "0312-FXB - Airport West",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0313",
        //                                     "SiteName": "0313-FXB - Plenty Valley",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0314",
        //                                     "SiteName": "0314-FXB - The Glen",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0315",
        //                                     "SiteName": "0315-FXB - Bayside",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0316",
        //                                     "SiteName": "0316-FXB - Swanston St",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0321",
        //                                     "SiteName": "0321-FXB - Bourke St Melbourne",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0325",
        //                                     "SiteName": "0325-FXB - Chadstone",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0326",
        //                                     "SiteName": "0326-FXB - Doncaster",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0328",
        //                                     "SiteName": "0328-FXB - Werribee Plaza",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0341",
        //                                     "SiteName": "0341-FXB - Geelong",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0343",
        //                                     "SiteName": "0343-FXB - Epping Plaza",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0375",
        //                                     "SiteName": "0375-FXB - Frankston",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0382",
        //                                     "SiteName": "0382-FXB - Ringwood",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0383",
        //                                     "SiteName": "0383-FXB - Maribyrnong",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0384",
        //                                     "SiteName": "0384-HWT - Cheltenham",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0387",
        //                                     "SiteName": "0387-Travel Agent - Fountain Gate",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0389",
        //                                     "SiteName": "0389-HWT - Ballarat",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0392",
        //                                     "SiteName": "0392-FXB - Preston",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0394",
        //                                     "SiteName": "0394-MELAIR - Wholesale",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0402",
        //                                     "SiteName": "0402-BNEAIR - Int L/side Arr",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0413",
        //                                     "SiteName": "0413-BNEAIR - Int L/side Dep Duty Free",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0418",
        //                                     "SiteName": "0418-BNEAIR - Int L/side Arr L2 Baggage",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0419",
        //                                     "SiteName": "0419-BNEAIR - Mobile A/side Arr",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0420",
        //                                     "SiteName": "0420-Brisbane International Airport",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0421",
        //                                     "SiteName": "0421-FXB - Queen St Mall Brisbane",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0427",
        //                                     "SiteName": "0427-FXB - Chermside",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0428",
        //                                     "SiteName": "0428-FXB - North Lakes",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0432",
        //                                     "SiteName": "0432-FXB - GARDEN CITY",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0433",
        //                                     "SiteName": "0433-CNSAIR-Intl Landside Arr",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0434",
        //                                     "SiteName": "0434-FXB - Castletown",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0435",
        //                                     "SiteName": "0435-FXB-Adelaide St Brisbane",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0440",
        //                                     "SiteName": "0440-FXB - Cairns Central",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0456",
        //                                     "SiteName": "0456-FXB - Townsville",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0457",
        //                                     "SiteName": "0457-FXB - INDOOROOPILLY",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0458",
        //                                     "SiteName": "0458-FXB - LOGAN HYPERDOME",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0459",
        //                                     "SiteName": "0459-FXB - Strathpine",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0465",
        //                                     "SiteName": "0465-FXB - Toowoomba",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0474",
        //                                     "SiteName": "0474-HWT - Mackay",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0479",
        //                                     "SiteName": "0479-FXB - Maroochydore",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0480",
        //                                     "SiteName": "0480-FXB Capalaba",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0494",
        //                                     "SiteName": "0494-FXB - Brookside",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0495",
        //                                     "SiteName": "0495-FXB - Carindale",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0506",
        //                                     "SiteName": "0506-ADLAIR - T1 Domestic Departure",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0507",
        //                                     "SiteName": "0507-ADLAIR - T1 L/side Arr",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0521",
        //                                     "SiteName": "0521-FXB - Rundle Mall",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0524",
        //                                     "SiteName": "0524-Travel Agent - Glenelg",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0572",
        //                                     "SiteName": "0572-HWT - Modbury",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0573",
        //                                     "SiteName": "0573-HWT - West Lakes",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0574",
        //                                     "SiteName": "0574-HWT - Marion",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0578",
        //                                     "SiteName": "0578-HWT - ELIZABETH",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0601",
        //                                     "SiteName": "0601-PERAIR - Int L/side Dep",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0602",
        //                                     "SiteName": "0602-PERAIR - Int L/side Arr",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0603",
        //                                     "SiteName": "0603-PERAIR - Int A/side Dep",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0604",
        //                                     "SiteName": "0604-PERAIR - Int A/side Arr",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0605",
        //                                     "SiteName": "0605-PERAIR - Qantas Domestic",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0606",
        //                                     "SiteName": "0606-PERAIR - Int A/side Arr Mobile",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0607",
        //                                     "SiteName": "0607-PERAIR - Intl L/Side Departures",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0608",
        //                                     "SiteName": "0608-PERAIR - Int A/side Dep T1 (On the Move)",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0609",
        //                                     "SiteName": "0609-PERAIR - Int A/side Dep T3 (On the Move)",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0610",
        //                                     "SiteName": "0610-PERAIR - Int A/side Arr T3 (On the Move)",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0652",
        //                                     "SiteName": "0652-FXB - Innaloo",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0653",
        //                                     "SiteName": "0653-FXB - Joondalup",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0657",
        //                                     "SiteName": "0657-FXB - Rockingham",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0658",
        //                                     "SiteName": "0658-FXB - Joondalup Lakeside",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0672",
        //                                     "SiteName": "0672-HWT - Hillarys",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0673",
        //                                     "SiteName": "0673-HWT - Morley",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0675",
        //                                     "SiteName": "0675-HWT - Belmont",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0677",
        //                                     "SiteName": "0677-FXB - Karrinyup",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0678",
        //                                     "SiteName": "0678-Mandurah",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0680",
        //                                     "SiteName": "0680-HWT - Carousel",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0683",
        //                                     "SiteName": "0683-FXB - Booragoon",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0772",
        //                                     "SiteName": "0772-HWT - Launceston",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0872",
        //                                     "SiteName": "0872-HWT - Belconnen",
        //                                     "missing": "M"
        //                                 },
        //                                 {
        //                                     "SiteId": "0874",
        //                                     "SiteName": "0874-Travel Agent - Woden",
        //                                     "missing": "M"
        //                                 }
        //                             ],
        //                             "inactive": []
        //                         }
        //                     }
        //                 ]
        //             }
        //         ]
        //     },
        //     "transactionData": [
        //         {
        //             "eodStatus": "success",
        //             "glStatus": "error",
        //             "warehouseStatus": null,
        //             "fileDate": "2024-12-14T00:00:00.000Z",
        //             "siteId": "0190",
        //             "fileName": "DES0190PRD349YR24.DE",
        //             "creationTime": "2025-01-16T10:17:50.000Z",
        //             "countryIso": "AU",
        //             "glIso": "AU",
        //             "warehouseIso": null,
        //             "executionId": "rts-eod-control-utility-AU-b159e596a067899a1672d5ccf38e173f1f403955-1737022664787166501"
        //         }
        //     ]
        // }
        let filterResponse = [];
        if (result.response?.regions) {
            let sites = result.response.regions[0].countries[0].sites;
            sites = sites.active.concat(sites.inactive);
            filterResponse = (siteName?.length) ?
              (selectedFlags?.length ? jp.query({ x: jp.query({ x: sites }, this.getSiteNameJSONFilterExpression(siteName)) }, this.getJSONPathFilterExpression(selectedFlags)) : jp.query({ x: sites }, this.getSiteNameJSONFilterExpression(siteName)))
                : (selectedFlags?.length) ? jp.query({ x: sites }, this.getJSONPathFilterExpression(selectedFlags)) : sites;
        }

        // console.log('getSiteAPIResponse', filterResponse)

        return filterResponse;
    }

    getSiteNameJSONFilterExpression = (selectedSites) => {

        let filterExpression = '$..[?(';
        selectedSites.forEach((site, index) => {
            filterExpression = filterExpression + (index === 0 ? `@.SiteName=="${site}"` : ` || @.SiteName=="${site}"`)
        });

        filterExpression = filterExpression + ')]';
        return filterExpression;
    }

    async componentDidMount() {
        this.setState({ sites: await this.getSiteAPIResponse(), isAPICallNeeded: this.props.isAPICallNeeded, loader: false });;
    }


    render() {

        if (this.state.loader) {
            return <div style={{ textAlign: "center" }}><Spin spin={true} size='large' /></div>
        } else {
            const stores = this.state;
            
            let columns = [
                { title: 'Stores', dataIndex: 'name', key: 'name', width: 300, render: text => <p style={{ float: "left" }}>{text}</p> },

            ];

            columns = [...columns, {
                title: 'Missing', dataIndex: 'missing', key: 'missing ', width: 100,

                render(text, record) {
                    return {
                        props: {
                            style: text ? { background: "Yellow", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <Tooltip title={text}><a>{text}</a></Tooltip>
                    };
                },
                onCell: (record, rowIndex) => {
                    return {
                        onClick: (e) => {
                            // console.log("Click Me Missing :: ", record, " -- ", rowIndex);
                            this.props.setAuditDetails(record, this.props.country, this.props.region, record.missing);
                        }
                    }
                }
            },
            {
                title: 'Closed', dataIndex: 'closed', key: 'closed ',
                render(text, record) {
                    return {
                        props: {
                            style: text ? { background: "Gray", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <Tooltip title={text}><a>{text}</a></Tooltip>
                    };
                }
            },
            {
                title: 'Error', dataIndex: 'error', key: 'error ',
                render(text, record) {
                    return {
                        props: {
                            style: text ? { background: "rgba(221,12,12,.815)", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <Tooltip title={text}><a>{text}</a></Tooltip>
                    };
                },
                onCell: (record, rowIndex) => {
                    return {
                        onClick: (e) => {
                            this.props.setAuditDetails(record, this.props.country, this.props.region, record.error);
                        }
                    }
                }
            },
            {
                title: 'Warehouse', dataIndex: 'warehouse', key: 'warehouse ',
                render(text, record) {
                    return {
                        props: {
                            style: text && ["EW", "EWCN", "EWAU"].includes(text) ? { background: "orange", padding: "10px" } : text && text === "IP" ? { background: "#8aacfa", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <Tooltip title={text}><a>{text}</a></Tooltip>
                    };
                },
                onCell: (record, rowIndex) => {
                    // console.log("Warehouse :: ", record);
                    if(record.warehouse !== "Y") {
                        return {
                            onClick: (e) => {
                                // console.log("Click Me Missing :: ", record, " -- ", rowIndex);
                                this.props.setAuditDetails(record, this.props.country, this.props.region, record.warehouse);
                            }
                        }
                    }
                    
                }
            },
            {
                title: 'Ledger', dataIndex: 'ledger', key: 'ledger',
                render(text, record) {
                    return {
                        props: {
                            style: text && text === "EL" ? { background: "red", padding: "10px" } : text && text === "IP" ? { background: "#8aacfa", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <Tooltip title={text}><a>{text}</a></Tooltip>
                    };
                },
                onCell: (record, rowIndex) => {
                    if(record.ledger !== "Y") {
                        return {
                            onClick: (e) => {
                                // console.log("Click Me Missing :: ", record, " -- ", rowIndex);
                                this.props.setAuditDetails(record, this.props.country, this.props.region, record.ledger);
                            }
                        }
                    }
                }
            },
            {
                title: 'Oracle Finance', dataIndex: 'oracleFinance', key: 'oracleFinance',
                render(text, record) {
                    return {
                        props: {
                            style: text && text === "EF" ? { background: "yellow", padding: "10px" } : text && text === "IP" ? { background: "#8aacfa", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <Tooltip title={text}><a>{text}</a></Tooltip>
                    };
                },
                onCell: (record, rowIndex) => {
                    if(record.oracleFinance !== "Y") {
                        return {
                            onClick: (e) => {
                                // console.log("Click Me  :: ", record, " -- ", rowIndex);
                                this.props.setAuditDetails(record, this.props.country, this.props.region, record.oracleFinance);
                            }
                        }
                    }
                }
            }
        
        ]

            const siteIds = stores.sites.map((site, index) => {
            // const siteIds = storesTemp.sites.map((site, index) => {

            // console.log('site', site)

                const result= {
                    key: index,
                    siteId: site.SiteId,
                    name: site.SiteName,
                    missing: jp.query(site, '$..missing')[0],
                    closed: jp.query(site, '$..closed')[0],
                    processing: jp.query(site, '$..processing')[0],
                    error: jp.query(site, '$..error')[0],
                    warehouse: jp.query(site, '$..warehouse')[0],
                    ledger: jp.query(site, '$..ledger')[0],
                    oracleFinance: jp.query(site, '$..oracleFinance')[0],//todo: change this later
                }
                // console.log('result', result)

                return result;
            })

            return <div className="storeTable">
                <Table
                    className="test"
                    size={"small"}
                    bordered="true"
                    columns={columns}
                    dataSource={siteIds}
                    pagination={false}
                    scroll={{
                        y: 240
                    }} />
            </div>
        }
    }
}


export default DaySites;