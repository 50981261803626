import '../../asset/css/style.css'
import '../../asset/css/table.css';

import { Modal, Spin, Table, Tooltip } from "antd";
import { useEffect, useRef, useState } from 'react';

import Constant from '../../util/Constant.json';
import { VariableSizeGrid as Grid } from 'react-window';
import React from "react";
import ResizeObserver from 'rc-resize-observer';
import SiteService from '../../service/Sites';
import classNames from 'classnames';
import { getURL } from '../../util/index';
import moment from "moment";

function VirtualTable(props) {
    const { columns, scroll } = props;
    const [tableWidth, setTableWidth] = useState(0);
    const widthColumnCount = columns.filter(({ width }) => !width).length;
    const mergedColumns = columns.map((column) => {
        
        if (column.width) {
            return column;
        }

        return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
    });


    const gridRef = useRef();
    const [connectObject] = useState(() => {
        const obj = {};
        Object.defineProperty(obj, 'scrollLeft', {
            get: () => null,
            set: (scrollLeft) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({
                        scrollLeft,
                    });
                }
            },
        });
        return obj;
    });

    const resetVirtualGrid = () => {
        if (gridRef.current) {
            gridRef.current.resetAfterIndices({
                columnIndex: 0,
                shouldForceUpdate: false,
            });
        }
    };

    useEffect(() => resetVirtualGrid, [tableWidth]);

    return (
        <ResizeObserver
            onResize={({ width }) => {
                setTableWidth(width);
            }}
            className="storeTable"
        >
            <Table
                {...props}
                columns={mergedColumns}
                pagination={false}
                className="test"
                tableLayout="fixed"
                bordered="true"
                // components={{
                //     body: renderVirtualList,
                // }}
                dataSource={props.dataSource}
            />
        </ResizeObserver>
    );
} // Usage

class ErrorSites extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            siteData: {
                sites: []
            },
            loader: <Spin spin={true} size='large' />,
        
        };
        localStorage.setItem(`${this.props.country}monthSiteLastRequestedURL`, null);
    }


    async componentDidMount() {
        try {
            let result = await this.getData();
            if (result)
                this.setState({ siteData: { sites: result || [] }, loader: '' });
        } catch (error) {
        }

    }

    async componentDidUpdate() {
        try {
            let result = await this.getData();
            // console.log(`result`, result);
            if (result)
                this.setState({ siteData: { sites: result || [] }, loader: '' });
        }
        catch (e) {
        }
    }

    getData = async () => {
        let url = new getURL(Constant.URL.GET_MONTHLY_DATA);
        url.searchParams.append('date', this.props.selectedDate);
        url.searchParams.append('region', this.props.region);
        url.searchParams.append('country', this.props.country);
        let statusValue = this.getStatusValue(this.props.filterData.status);
        if (statusValue !== null) url.searchParams.append('status', statusValue);
        if (localStorage.getItem(`${this.props.country}monthSiteLastRequestedURL`) !== url.href) {
            localStorage.setItem(`${this.props.country}monthSiteLastRequestedURL`, url.href);
            let availableData = await SiteService.getMonthData(url.href, {});
            // console.log('monthdata', availableData);
            // availableData= [
            //     {
            //         "id": "0190",
            //         "siteName": "0190-FXB - Narellan",
            //         "updationTime": "2025-01-16T10:22:18.310Z",
            //         "days": [
            //             null,
            //             null,
            //             null,
            //             null,
            //             null,
            //             null,
            //             null,
            //             null,
            //             null,
            //             null,
            //             null,
            //             null,
            //             null,
            //             "EL#PS109",
            //             "EF#PS109"
            //         ],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0191",
            //         "siteName": "0191-Helloworld - Greenhills",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0193",
            //         "siteName": "0193-FXB - Tuggerah",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0195",
            //         "siteName": "0195-FXB - ROBINA",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0197",
            //         "siteName": "0197-FXB - KOTARA",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0199",
            //         "siteName": "0199-FXB - CHARLESTOWN",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0200",
            //         "siteName": "0200-SYDAIR - T2 Virgin Domestic",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0214",
            //         "siteName": "0214-SYDAIR - T3 Qantas Dom Gate 3",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0217",
            //         "siteName": "0217-FXB - Balgowlah",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0221",
            //         "siteName": "0221-FXB - QVB Sydney",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0227",
            //         "siteName": "0227-FXB - Figtree",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0244",
            //         "siteName": "0244-FXB - Penrith",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0245",
            //         "siteName": "0245-FXB - Bondi Junction",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0247",
            //         "siteName": "0247-FXB- Mt Druitt",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0249",
            //         "siteName": "0249-FXB - Macarthur Square",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0257",
            //         "siteName": "0257-Travelex at HSBC - Macquarie Centre",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0267",
            //         "siteName": "0267-FXB - Wetherill Park",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0268",
            //         "siteName": "0268-FXB - Shellharbour",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0269",
            //         "siteName": "0269-FXB - Warringah Mall",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0273",
            //         "siteName": "0273-HWT - Blacktown",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0277",
            //         "siteName": "0277-FXB - Burwood",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0279",
            //         "siteName": "0279-FXB - Liverpool",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0282",
            //         "siteName": "0282-HWT - Miranda",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0285",
            //         "siteName": "0285-FXB - Castle Towers",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0289",
            //         "siteName": "0289-HWT - Erina",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0293",
            //         "siteName": "0293-HWT - East Gardens",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0294",
            //         "siteName": "0294-FXB - Hornsby",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0295",
            //         "siteName": "0295-HWT - Albury",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0311",
            //         "siteName": "0311-MELAIR - T2 A/side Arr Baggage",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0312",
            //         "siteName": "0312-FXB - Airport West",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0313",
            //         "siteName": "0313-FXB - Plenty Valley",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0314",
            //         "siteName": "0314-FXB - The Glen",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0315",
            //         "siteName": "0315-FXB - Bayside",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0316",
            //         "siteName": "0316-FXB - Swanston St",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0321",
            //         "siteName": "0321-FXB - Bourke St Melbourne",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0325",
            //         "siteName": "0325-FXB - Chadstone",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0326",
            //         "siteName": "0326-FXB - Doncaster",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0328",
            //         "siteName": "0328-FXB - Werribee Plaza",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0341",
            //         "siteName": "0341-FXB - Geelong",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0343",
            //         "siteName": "0343-FXB - Epping Plaza",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0375",
            //         "siteName": "0375-FXB - Frankston",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0382",
            //         "siteName": "0382-FXB - Ringwood",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0383",
            //         "siteName": "0383-FXB - Maribyrnong",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0384",
            //         "siteName": "0384-HWT - Cheltenham",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0387",
            //         "siteName": "0387-Travel Agent - Fountain Gate",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0389",
            //         "siteName": "0389-HWT - Ballarat",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0392",
            //         "siteName": "0392-FXB - Preston",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0394",
            //         "siteName": "0394-MELAIR - Wholesale",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0402",
            //         "siteName": "0402-BNEAIR - Int L/side Arr",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0413",
            //         "siteName": "0413-BNEAIR - Int L/side Dep Duty Free",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0418",
            //         "siteName": "0418-BNEAIR - Int L/side Arr L2 Baggage",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0419",
            //         "siteName": "0419-BNEAIR - Mobile A/side Arr",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0420",
            //         "siteName": "0420-Brisbane International Airport",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0421",
            //         "siteName": "0421-FXB - Queen St Mall Brisbane",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0427",
            //         "siteName": "0427-FXB - Chermside",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0428",
            //         "siteName": "0428-FXB - North Lakes",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0432",
            //         "siteName": "0432-FXB - GARDEN CITY",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0433",
            //         "siteName": "0433-CNSAIR-Intl Landside Arr",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0434",
            //         "siteName": "0434-FXB - Castletown",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0435",
            //         "siteName": "0435-FXB-Adelaide St Brisbane",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0440",
            //         "siteName": "0440-FXB - Cairns Central",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0456",
            //         "siteName": "0456-FXB - Townsville",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0457",
            //         "siteName": "0457-FXB - INDOOROOPILLY",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0458",
            //         "siteName": "0458-FXB - LOGAN HYPERDOME",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0459",
            //         "siteName": "0459-FXB - Strathpine",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0465",
            //         "siteName": "0465-FXB - Toowoomba",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0474",
            //         "siteName": "0474-HWT - Mackay",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0479",
            //         "siteName": "0479-FXB - Maroochydore",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0480",
            //         "siteName": "0480-FXB Capalaba",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0494",
            //         "siteName": "0494-FXB - Brookside",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0495",
            //         "siteName": "0495-FXB - Carindale",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0506",
            //         "siteName": "0506-ADLAIR - T1 Domestic Departure",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0507",
            //         "siteName": "0507-ADLAIR - T1 L/side Arr",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0521",
            //         "siteName": "0521-FXB - Rundle Mall",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0524",
            //         "siteName": "0524-Travel Agent - Glenelg",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0572",
            //         "siteName": "0572-HWT - Modbury",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0573",
            //         "siteName": "0573-HWT - West Lakes",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0574",
            //         "siteName": "0574-HWT - Marion",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0578",
            //         "siteName": "0578-HWT - ELIZABETH",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0601",
            //         "siteName": "0601-PERAIR - Int L/side Dep",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0602",
            //         "siteName": "0602-PERAIR - Int L/side Arr",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0603",
            //         "siteName": "0603-PERAIR - Int A/side Dep",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0604",
            //         "siteName": "0604-PERAIR - Int A/side Arr",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0605",
            //         "siteName": "0605-PERAIR - Qantas Domestic",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0606",
            //         "siteName": "0606-PERAIR - Int A/side Arr Mobile",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0607",
            //         "siteName": "0607-PERAIR - Intl L/Side Departures",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0608",
            //         "siteName": "0608-PERAIR - Int A/side Dep T1 (On the Move)",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0609",
            //         "siteName": "0609-PERAIR - Int A/side Dep T3 (On the Move)",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0610",
            //         "siteName": "0610-PERAIR - Int A/side Arr T3 (On the Move)",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0652",
            //         "siteName": "0652-FXB - Innaloo",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0653",
            //         "siteName": "0653-FXB - Joondalup",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0657",
            //         "siteName": "0657-FXB - Rockingham",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0658",
            //         "siteName": "0658-FXB - Joondalup Lakeside",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0672",
            //         "siteName": "0672-HWT - Hillarys",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0673",
            //         "siteName": "0673-HWT - Morley",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0675",
            //         "siteName": "0675-HWT - Belmont",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0677",
            //         "siteName": "0677-FXB - Karrinyup",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0678",
            //         "siteName": "0678-Mandurah",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0680",
            //         "siteName": "0680-HWT - Carousel",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0683",
            //         "siteName": "0683-FXB - Booragoon",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0772",
            //         "siteName": "0772-HWT - Launceston",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0872",
            //         "siteName": "0872-HWT - Belconnen",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     },
            //     {
            //         "id": "0874",
            //         "siteName": "0874-Travel Agent - Woden",
            //         "updationTime": "",
            //         "days": [],
            //         "ExportActive": "1"
            //     }
            // ]
            return availableData;
        }
        else {
            throw new Error('Same data stopped');
        }
    }

    getStatusValue(input) {
        if (input.length === 1) {
            return input[0].toLocaleUpperCase() === 'ACTIVE' ? true : false;
        }
        else {
            return null;
        }
    }

    getClassName(dayFlag) {

        if (dayFlag.startsWith("C")) return "closedTile"

        switch (dayFlag) {
            case "M":
                return "missingTile";
            case "E":
                return "fileProcessingTile";
            case "EL":
                return "ledgerErrorTile";
            case "EW":
                return "warehouseErrorTile";
            case "Y":
                return "processingTile";
            case "ELW":
                return "lederWareHouseErrorTile"
            case "IP":
                return "inProgressTile"
            case "EF":
                return "financeErrorTile";
            default:
                return '';
        }

    }

    renderClassNameTemp(text) {
        let className = "";
        let completeText = ""
        if (text) {
            let splittedText = text.split("|");  //check for multiple pipe seperator
            for (let splittedTextIndex = 0; splittedTextIndex < splittedText.length; splittedTextIndex++) {

                if (splittedText[splittedTextIndex]) {
                    let delimiter = splittedText[splittedTextIndex].lastIndexOf("#");  //get the # part 


                    let substringFrom = 0; //from were we need to split the string
                    if (delimiter > 2) {
                        substringFrom = 2; //if delimiter is more than 2 then we need show the second the code 
                    } else if (delimiter === -1) {
                        delimiter = splittedText[splittedTextIndex].length //only when no # is present
                    }

                    if (completeText) substringFrom++;  //if EL & EW both are present

                    completeText += splittedText[splittedTextIndex].substring(substringFrom, delimiter); //create completeText
                }
            }
            className = this.getClassName(completeText); //get the className
        }else {
            className = "emptyData";
        }
        return className;
    }
    
    getCompleteText(text) {

        let completeText = ""
        if (text) {

            let splittedText = text.split("|");  //check for multiple pipe seperator
            for (let splittedTextIndex = 0; splittedTextIndex < splittedText.length; splittedTextIndex++) {

                if (splittedText[splittedTextIndex]) {
                    let delimiter = splittedText[splittedTextIndex].lastIndexOf("#");  //get the # part 

                    let substringFrom = 0; //from were we need to split the string
                    if (delimiter > 2) {
                        substringFrom = 2; //if delimiter is more than 2 then we need show the second the code 
                    } else if (delimiter === -1) {
                        delimiter = splittedText[splittedTextIndex].length //only when no # is present
                    }

                    if (completeText) substringFrom++;  //if EL & EW both are present

                    completeText += splittedText[splittedTextIndex].substring(substringFrom, delimiter); //create completeText
                }
            }
        }
        return completeText;
            
    }

    render() {
        const stores = this.state.siteData;
        
        const noOfDays = moment(this.props.selectedDate, "YYYY-MM").daysInMonth();
        const dayOfSelectedMonth = moment(this.props.selectedDate, "YYYY-MM").dayOfYear();
        let dynamicColumns = [];
        let columns = [
            {
                title: 'Stores',
                dataIndex: 'name',
                key: 'name',
                width: 200,
                // fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (text) => (
                    <Tooltip title={text}>
                        <span style={{ textAlign: "center" }}>{text}</span>
                    </Tooltip>
                ),
                onCell: (record) => {
                    return{
                        className: "emptyData"
                    }
                }
            }
        ];

        //create columns first
        for (let columnIndex = 0; columnIndex < noOfDays; columnIndex++) {
            const singleObj = {
                title: <Tooltip title={dayOfSelectedMonth + columnIndex}>{`${columnIndex + 1}`}</Tooltip>, //month number
                dataIndex: columnIndex,
                key: columnIndex,
                width: noOfDays.length > 99 ? 45 : 34,  //this will assigned the width 
                ellipsis: {
                    showTitle: false,
                },
                render: (text) => {
                    return{
                        props: {className: text ? (this.renderClassNameTemp(text)): ("")},
                        children : <Tooltip title={text}><a data-id={columnIndex+1} onClick={(e) => this.props.setColumnName(e, this.props.country, this.props.region, text)}>{text ? this.getCompleteText(text): ("")}</a></Tooltip>
                    }
                },
                onCell: (record, rowIndex) => {
                    return{
                      onClick: (e) => {
                        this.props.setAuditDetails(record);
                      }
                    }
                  }
            }
            columns = [...columns, singleObj]
        }
        let values = []
        let siteIds = [];
        //Now create stores first with id and days

        if (this.state.loader) {
            return <div style={{ textAlign: "center" }}>{this.state.loader}</div>
        } else {
            if (stores) {

                values = { ...stores };

                if (values.sites && values.sites.length && this.props.filterData.siteName && this.props.filterData.siteName.length) {
                    values.sites = values.sites.filter(d => this.props.filterData.siteName.includes(d.siteName));
                }
                values.sites.forEach((site) => {
                    let singleObj = {
                        key: site.id,
                        name: site.siteName
                    }
                    site.days.forEach((day, i) => {

                        day = day ? day : ""
                        singleObj = { ...singleObj, ...{ [i]: day } }

                    });

                    siteIds = [...siteIds, singleObj]
                })
            }


            dynamicColumns = [...columns, ...dynamicColumns]
            
            if (siteIds.length) {
                return (
                    <VirtualTable
                        columns={dynamicColumns}
                        dataSource={siteIds}
                        scroll={{
                            y: 240,
                            x: '1200',
                        }}
                    />
                )
            }
            else
                return <div className="ant-empty ant-empty-normal"><div className="ant-empty-image"><svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g className="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path></g></g></svg></div><div className="ant-empty-description">No Data</div></div>;

        }

    }
}



export default ErrorSites;