import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Checkbox, DatePicker, Input } from 'antd';
import { Col, Row } from 'react-bootstrap';

import { BsSearch } from "react-icons/bs";
import Form from 'react-bootstrap/Form';
import React from 'react';
import moment from "moment";

class TextBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };
        this.myRef = React.createRef();
    }

    setValue = (event) => {
        this.setState({ value: event.target.value });
        if (event.key === 'Enter') {
            console.log('do validate')
        }
    }

    getValue = () => {
        this.props.getValue(this.myRef.current.value);
    }

    render() {
        return (
            <React.Fragment>
                <input type='text' ref={this.myRef} placeholder={this.props.placeholder} onKeyUp={this.setValue} />
                <button onClick={this.getValue}> Search </button>
            </React.Fragment>
        )
    }
}

class Flags extends React.Component {


    render() {

        return <div style={{ backgroundColor: "#e3f1fe" }}>
            {this.props.flags.map((flag, index) => {
                return <Form.Check
                    key={index}
                    onChange={this.props.onFlagChange}
                    className="flags"
                    type="checkbox"
                    id={flag.flagName}
                    label={flag.title}
                    checked={flag.selected ? true : false}
                />
            })
            }
        </div>
    }
}

class MonthDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: null };
    }

    changeDate = (date, dateString) => {
        this.setState({ value: dateString }, () => {
            this.props.onMonthChange(dateString);
        });

    }

    render() {
        return (
            <React.Fragment>
                <DatePicker defaultValue={moment()} allowClear={false} onChange={(date, dateString) => this.changeDate(date, dateString)} picker="month" />
            </React.Fragment>
        )
    }
}

class CustomDatePicker extends React.Component {

}

class CheckBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { checkedValue: [], facets: this.props.facets, checkedStatus: ["Active"] };
        this.myRef = React.createRef();
    }

    getCheckedValue = (e, record) => {
        if (this.props.selectedList.includes(e.target.id)) {
            let checkedValue = this.props.selectedList;
            checkedValue.splice(checkedValue.indexOf(e.target.id), 1)
            this.props.onChange(checkedValue);
        }
        else {
            let checkedValue = this.props.selectedList;
            checkedValue.push(e.target.id);
            this.props.onChange(checkedValue);
        }
    }

    searchTerm = async event => {
        // this.props.onSearch(this.myRef.current.state.value);
        this.props.onSearch(this.myRef.current.input.value);        
    }

    setValue = (event) => {
        if (event.key === 'Enter') {
            this.searchTerm(event);
        }
    }

    render() {
        const { facetsList, facetName } = this.props;
        let selectedValue = this.props.selectedList || [];
        return (
            <React.Fragment>
                <Row>
                    <Col sm={10} md={10} lg={10}>
                        <Input
                            ref={this.myRef}
                            className="search-input"
                            onKeyUp={this.setValue}
                        />
                    </Col>
                    <Col sm={1} md={1} lg={1} className="search__button"><BsSearch onClick={this.searchTerm} /></Col>

                </Row>
                {

                    facetsList && facetsList.length ? facetsList.map((record, index) => {
                        return <React.Fragment key={record}>
                            <Checkbox id={record}  onChange={e => this.getCheckedValue(e, record.toString(), facetName)} checked={selectedValue.includes(record)}>
                                {record.toString()}
                            </Checkbox><br />
                        </React.Fragment>

                    }) : ''
                }
                { 
                this.props.isDataTruncated ? <div className="data-truncate">Remaining values not displayed</div> : null}
            </React.Fragment>
        )
    }
}

const InputComponents = {
    TextBox,
    Flags,
    MonthDatePicker,
    CustomDatePicker,
    CheckBox
};
export default InputComponents;
